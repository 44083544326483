import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage, Checkbox } from '..'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../Utils/theme'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  },
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  }
}))

function ItemTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const openItem = () => null

  return (
    <TableRow
      classes={{ root: classes.root }}
      onClick={openItem}
      hover
    >
      <TableCell className={classes.cell}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.selectedIds.includes(item.id)}
            onChange={() => props.toggleSelect(item.id)}
            label=''
          />
        </div>
      </TableCell>
      {[
        item?.name,
        item?.contractType,
        item?.languageSkills,
        item?.city,
        item?.shiftDuration,
        item?.feedbackAvg,
      ].map((content, index) => {
        return (
          <TableCell key={index} className={classes.cell} onClick={openItem}>
            <span className={index ? classes.secondary : ''}>{content}</span>
          </TableCell>
        )
      })}
    </TableRow>

  )
}

function EmployeeTable(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <ItemTableRow
            item={item}
            onSelect={() => null}
            selectedIds={props.selectedIds}
            toggleSelect={props.toggleSelect}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const isAllSelected = () => {
    if (!props.selectedIds.length || props.selectedIds.length < props.items.length) return false
    for (const item of props.items) {
      if (!props.selectedIds.includes(item.id)) {
        return false
      }
    }
    return true
  }

  const toggleAll = () => {
    const all = isAllSelected()
    if (all) {
      props.clearSelect()
    } else {
      // Add new items to selection
      props.selectAll(props.items.map(item => item.id))
    }
  }

  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader
          columnNames={[t('employee_name'), t('employee_contract_type'), t('language_skills'), t('location'), t('shift_duration'), t('feedbacks')]}
          sort={props.sort}
          sortKeys={props.sortKeys}
          onSort={props.onSort}
          onSelect={toggleAll}
          selected={isAllSelected()}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_results')} />
    </div>
  )
}

export default EmployeeTable
