import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import {
  GeneralFeedbackTable,
  PageContainer,
  PageHeader,
  PageContent,
  NotificationsActions,
  TableHeaderActions
} from '../../../Components'
import { useNavigate } from 'react-router-dom'
import { uniq } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const CATEGORY = {
  all: 'all'
}

const SORT_KEYS = ['feedbackgiver', 'feedbackdate', 'wantsanswer', 'email']
const SORT_DIRECTIONS = ['asc', 'desc']

enum TabType {
  general = 'general',
  cleaning = 'cleaning'
}


function GeneralFeedbacks() {
  const classes = useStyles()

  const { sessionStore, generalFeedbackStore }: any = useStore()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(`${SORT_KEYS[0]}_${SORT_DIRECTIONS[0]}`)
  const [category, setCategory] = useState(CATEGORY.all)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const toggleSelect = (id: number) => {
    // Try remove
    const newIds: number[] = selectedIds.filter(val => val !== id)
    if (newIds.length !== selectedIds.length) {
      setSelectedIds(newIds)
    } else {
      // Add new
      setSelectedIds([...selectedIds, id])
    }
  }
  const selectAll = (ids) => {
    setSelectedIds(uniq([...selectedIds, ...ids]))
  }
  const clearSelect = () => setSelectedIds([])

  useEffect(() => {
    refreshItems()
  }, [])

  const { t } = useTranslation()

  const refreshItems = (newParams = {}) => {
    generalFeedbackStore.getGeneralFeedbacks({
      search,
      perPage,
      page,
      category,
      sort,
      ...newParams
    })
  }

  const handleTagChange = (value) => {
    if (value === TabType.cleaning) {
      navigate('/shift-feedbacks')
    }
  }

  const handleSetCategory = (category) => {
    setCategory(category)
    refreshItems({ category })
  }

  const handleSetSearch = (search) => {
    setSearch(search)
    refreshItems({ search })
  }

  const handleSetPerPage = (perPage) => {
    setPerPage(perPage)
    refreshItems({ perPage })
  }

  const handleSetSort = (sort) => {
    setSort(sort)
    refreshItems({ sort })
  }

  const getSortOptions = () => {
    const options: any = []
    for (const direction of SORT_DIRECTIONS) {
      for (const sortKey of SORT_KEYS) {
        const fullSortKey = `${sortKey}_${direction}`
        options.push({
          value: fullSortKey,
          label: t(`sort_${fullSortKey}`)
        })
      }
    }
    return options
  }

  const getCategoryOptions = () => {
    return [
      {
        label: t('all_feedbacks'),
        value: CATEGORY.all
      },
      ...(generalFeedbackStore.categories || []).map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    ]
  }

  return (
    <PageContainer>
      <PageHeader
        title={t('app_feedbacks')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        tab={TabType.general}
        setTab={handleTagChange}
        tabs={[
          {
            name: t('general_feedbacks_short'),
            value: TabType.general

          },
          {
            name: t('cleaning_feedbacks'),
            value: TabType.cleaning
          }
        ]}
      />
      <PageContent>
        <NotificationsActions
          sort={sort}
          setSort={handleSetSort}
          sortOptions={getSortOptions()}
          category={category}
          setCategory={handleSetCategory}
          categoryOptions={getCategoryOptions()}
        />
        <TableHeaderActions
          search={search}
          setSearch={handleSetSearch}
          perPage={perPage}
          setPerPage={handleSetPerPage}
          total={generalFeedbackStore.total}
        />
        <GeneralFeedbackTable
          items={generalFeedbackStore.generalFeedbacks}
          sort={sort}
          sortKeys={SORT_KEYS}
          onSort={handleSetSort}
          selectedIds={selectedIds}
          toggleSelect={toggleSelect}
          selectAll={selectAll}
          clearSelect={clearSelect}
        />
      </PageContent>
    </PageContainer>
  )
}

export default observer(GeneralFeedbacks)
