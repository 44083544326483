import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import {
  UserTable,
  PageContainer,
  PageHeader,
  PageContent,
  NotificationsActions,
  TableHeaderActions
} from '../../../Components'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const SORT = {
  az: 'az',
  time: 'time'
}
const CATEGORY = {
  all: 'all'
}

function Users() {
  const classes = useStyles()

  const { sessionStore, userStore }: any = useStore()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(SORT.time)
  const [category, setCategory] = useState(CATEGORY.all)

  useEffect(() => {
    refreshItems()
  }, [])

  const { t } = useTranslation()

  const refreshItems = (newParams = {}) => {
    userStore.getUsers({
      search,
      perPage,
      page,
      category,
      ...newParams
    })
  }



  const handleSetSearch = (search) => {
    setSearch(search)
    refreshItems({ search })
  }

  const handleSetPerPage = (perPage) => {
    setPerPage(perPage)
    refreshItems({ perPage })
  }





  return (
    <PageContainer>
      <PageHeader
        title={t('users')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
      />
      <PageContent>
        {/*
        <NotificationsActions
          sort={sort}
          setSort={setSort}
          sortOptions={getSortOptions()}
          category={category}
          setCategory={handleSetCategory}
          categoryOptions={getCategoryOptions()}
        />
        */}
        <TableHeaderActions
          search={search}
          setSearch={handleSetSearch}
          perPage={perPage}
          setPerPage={handleSetPerPage}
          total={userStore.total}
        />
        <UserTable
          items={userStore.users}
        />
      </PageContent>
    </PageContainer>
  )
}

export default observer(Users)

