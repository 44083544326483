import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import NotificationIcon from '../../../Assets/Icons/notification-dark.svg'
import UserIcon from '../../../Assets/Icons/user-dark.svg'
import SecurityIcon from '../../../Assets/Icons/security.svg'
import ProfileCardIcon from '../../../Assets/Icons/profile-card.svg'
import OfferIcon from '../../../Assets/Icons/offer.svg'

import { PageContainer, PageHeader, PageContent, Select, Checkbox, Input, Button } from '../../../Components'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2.5rem'
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  main: {
    flex: 1
  },
  itemIcon: {
    height: '1.5625rem'
  },
  itemText: {
    marginLeft: '-1rem'
  },
  itemTextSpan: {
    fontSize: '1rem'
  },
  innerContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  boxSpacer: {
    width: '2rem',
    height: '2rem'
  },
  menuBox: {
    [theme.breakpoints.up('md')]: {
      width: '15rem',
    },
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '.25rem 0'
  },
  contentBox: {
    padding: '2rem 2.5rem',
    flex: 1,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem'
  },
  listItem: {
    paddingLeft: '1.5rem'
  },
  h2: {
    margin: '0 0 1rem',
    fontFamily: 'Ambit',
    fontSize: '1.875rem',
    color: Colors.navy,
    lineHeight: '1.1'
  },
  h3: {
    fontSize: '1.5rem',
    margin: '1rem 0 1rem',
    fontFamily: 'Ambit',
    color: Colors.navy,
    lineHeight: '1.1'
  },
  description: {
    color: Colors.text
  },
  selectContainer: {
    width: '15rem'
  },
  spacer: {
    width: '1rem',
    height: '1rem'
  },
  cancelButton: {
    border: 0,
  },
  cancelButtonText: {
    fontWeight: 700
  },
  contactBox: {
    border: `1px solid ${Colors.border}`,
    padding: '2rem',
    borderRadius: '.625rem',
    marginBottom: '1rem'
  },
  contactRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem'
  },
  emptyText: {
    color: Colors.text,
    textAlign: 'center',
    padding: '2rem 0'
  }
}))

enum SettingsTab {
  notification = 'notification',
  userRole = 'user_role',
  security = 'security',
  contactInfo = 'contact_info',
  offers = 'offers'
}

function Settings() {
  const classes = useStyles()

  const { sessionStore }: any = useStore()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [tab, setTab] = useState(SettingsTab.notification)

  // Notification settings
  const [registerNotifications, setRegisterNotifications] = useState(false)
  const toggleRegisterNotifications = () => setRegisterNotifications(!registerNotifications)
  const [orderNotifications, setOrderNotifications] = useState(false)
  const toggleOrderNotifications = () => setOrderNotifications(!orderNotifications)
  const [contactRequestNotifications, setContactRequestNotifications] = useState(false)
  const toggleContactRequestNotifications = () => setContactRequestNotifications(!contactRequestNotifications)
  const [feedbackNotifications, setFeedbackNotifications] = useState(false)
  const toggleFeedbackNotifications = () => setFeedbackNotifications(!feedbackNotifications)
  const [changeRequestNotifications, setChangeRequestNotifications] = useState(false)
  const toggleChangeRequestNotifications = () => setChangeRequestNotifications(!changeRequestNotifications)
  const [gdprNotifications, setGdprNotifications] = useState(false)
  const toggleGdprNotifications = () => setGdprNotifications(!gdprNotifications)

  // Contact information
  const [introTitle, setIntroTitle] = useState('')
  const [introDescription, setIntroDescription] = useState('')
  const [contacts, setContacts] = useState([])
  const addContact = () => {
    setContacts([...contacts, { name: '', phone: '' }])
  }
  const setContactField = (index, key, value) => {
    setContacts(contacts.map((item, idx) => {
      if (idx !== index) return item
      return ({
        ...item,
        [key]: value
      })
    }))
  }

  const renderMenuItem = (item) => {
    return (
      <ListItem
        onClick={() => setTab(item.key)}
        classes={{ root: classes.listItem }}
        title={t(item.text)}
        disableGutters
        button
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          <img
            src={item.icon}
            className={classes.itemIcon}
            alt={item.text}
          />
        </ListItemIcon>
        <ListItemText classes={{ root: classes.itemText }}><span className={classes.itemTextSpan}>{item.text}</span></ListItemText>
      </ListItem>
    )
  }


  const renderMenu = () => {
    return (
      <div className={classes.menuBox}>
        <List>
          {renderMenuItem({ key: SettingsTab.notification, icon: NotificationIcon, text: t('notification_settings') })}
          {renderMenuItem({ key: SettingsTab.userRole, icon: UserIcon, text: t('user_levels') })}
          {renderMenuItem({ key: SettingsTab.security, icon: SecurityIcon, text: t('security') })}
          {renderMenuItem({ key: SettingsTab.contactInfo, icon: ProfileCardIcon, text: t('contact_informations') })}
          {renderMenuItem({ key: SettingsTab.offers, icon: OfferIcon, text: t('start_screen_offers') })}
        </List>
      </div>
    )
  }




  const renderContent = () => {
    if (tab === SettingsTab.notification) {
      return (
        <div>
          <h3 className={classes.h2}>{t('notification_settings')}</h3>
          <p className={classes.description}>{t('notification_settings_description')}</p>
          <h3 className={classes.h3}>{t('client_app_events')}</h3>
          <div>
            <Checkbox
              label={t('new_customer_registrations')}
              checked={registerNotifications}
              onChange={toggleRegisterNotifications}
            />
          </div>
          <div>
            <Checkbox
              label={t('new_orders')}
              checked={orderNotifications}
              onChange={toggleOrderNotifications}
            />
          </div>
          <div>
            <Checkbox
              label={t('new_contact_requests')}
              checked={contactRequestNotifications}
              onChange={toggleContactRequestNotifications}
            />
          </div>
          <div>
            <Checkbox
              label={t('new_cleaning_feedbacks')}
              checked={feedbackNotifications}
              onChange={toggleFeedbackNotifications}
            />
          </div>
          <div>
            <Checkbox
              label={t('change_requests')}
              checked={changeRequestNotifications}
              onChange={toggleChangeRequestNotifications}
            />
          </div>
          <div>
            <Checkbox
              label={t('account_removal_requests')}
              checked={gdprNotifications}
              onChange={toggleGdprNotifications}
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.selectContainer}>
            <Select
              options={[{ label: t('from_all_events'), value: 'all' }]}
              value={'all'}
              label={t('notification_frequency')}
              noMargin
            />
          </div>
        </div>
      )
    }
    if (tab === SettingsTab.userRole) {
      return (
        <div>
          <h3 className={classes.h2}>{t('user_levels')}</h3>
        </div>
      )
    }
    if (tab === SettingsTab.contactInfo) {
      return (
        <div>
          <h3 className={classes.h2}>{t('contact_informations')}</h3>
          <p className={classes.description}>{t('contact_info_shown_on_mobile_app')}</p>
          <h3 className={classes.h3}>{t('intro_of_view')}</h3>
          <Input
            label={t('title_of_view')}
            value={introTitle}
            onChange={setIntroTitle}
          />

          {t('description_of_view')}
          <h3>{t('list_of_municipalities')}</h3>
          <div class={classes.contactBox}>
            {!contacts.length && (
              <div className={classes.emptyText}>
                {t('no_contact_infos_guide')}
              </div>
            )}
            {contacts.map((item, index) => {
              return (
                <div className={classes.contactRow}>
                  <Input
                    label={t('name')}
                    value={item.name}
                    onChange={(value) => setContactField(index, 'name', value)}
                  />
                  <Input
                    label={t('phone')}
                    value={item.phone}
                    onChange={(value) => setContactField(index, 'phone', value)}
                  />
                </div>
              )
            })}
          </div>
          <Button
            text={t('add_contact_info')}
            onClick={addContact}
          />
        </div>
      )
    }
  }

  return (
    <PageContainer>
      <div className={classes.rootContainer}>
        <div className={classes.main}>
          <PageHeader
            title={t('settings')}
            user={sessionStore.user}
          />
          <PageContent size='medium'>
            <div className={classes.innerContainer}>
              <div>
                {renderMenu()}
              </div>
              <div className={classes.boxSpacer} />
              <div className={classes.contentBox}>
                {renderContent()}
              </div>
            </div>
          </PageContent>
        </div>
      </div>
    </PageContainer>
  )
}

export default observer(Settings)
